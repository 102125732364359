import React, { Component } from 'react';
import '../../App.css';
import { ReactComponent as FacebookLogo } from '../images/icons/facebook.svg';
import { ReactComponent as InstagramLogo } from '../images/icons/instagram.svg';

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          <footer className="row">
            <div className="column">
              <div>
                <h3>Adres</h3>
                <ul>
                  <li>Berkenhof 67</li>
                  <li>Papendrecht</li>
                </ul>
              </div>
            </div>
            <div className="column">
              <div>
                <h3>Bedrijfsinformatie</h3>
                <ul>
                  <li>KvK: 77095324</li>
                  <li>IBAN: NL67 INGB 0009175111</li>
                </ul>
              </div>
            </div>
            <div className="column">
              <div>
                <h3>Contact</h3>
                <ul>
                  <li><a href="mailto:info@karsafdichtingstechnieken.nl">info@karsafdichtingstechnieken.nl</a></li>
                  <li><a href="tel:+31615564767">Mobiel: 0615564767</a></li>
                  <li><a href="tel:+31652609695">Administratie: 0652609695</a></li>
                </ul>
              </div>
            </div>
          </footer>
        );
    }
}

export default Footer;